import React, { useState } from "react";
import googleCloud from "../../assets/img/Landing page-slicing/Google-Cloud.png";
import googleGemini from "../../assets/img/Landing page-slicing/Google-Gemini.png";

import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar as RC_Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <footer className="page-footer font-small blue  position-relative">
        {/* <Row lg={3} md={2} xs={1} className="mx-md-5 mx-2 px-md-3 px-0 pb-3  pt-5">

          <Col className="pl-0">
            <NavbarBrand className="navbar-header" href="/">
              <img src={Logo} className="footer-logo"/>
            </NavbarBrand>
          </Col>
          <Col className="footer-nav">
            <a href="" className="footer-btn">
              <img src={iosbtn} />
            </a>
            <a href="" className="footer-btn">
              <img src={androidbtn} />
            </a>
          </Col>
          <Col className="footer-social px-0">
            <Link href="#" className="social-btn">
              <img src={social1} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social2} />
            </Link>

            <Link href="#" className="social-btn">
              <img src={social3} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social4} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social5} />
            </Link>
          </Col>
        </Row> */}
        <div className="d-flex align-items-center py-5 mx-md-5 mx-2 px-md-3 px-0">
          {/* <img src={bannerImage} alt="banner" width='100%' /> */}
          <h2
            className="font-family-3 pt-4 w-100 text-center"
            style={{ fontWeight: 600 }}
          >
            Mobile Team Communication and Collaboration
            <br />
            With Built In Google Gemini Artificial Intelligence
          </h2>
          {/* <div style={{ flex: 1 }} />
          <div style={{ width: "330px" }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: bannerImage,
              }}
              height={175}
              width={175}
              style={{ margin: 0 }}
            />
          </div> */}
        </div>

        <Row lg={3} md={2} xs={1} className="mx-md-5 mx-2 px-md-3 px-0 pb-3">
          <Col className="d-flex align-items-center pb-3 pb-md-0 pt-1 px-0">
            <span className="mx-auto mx-md-0">
              280 N Old Woodward
              {/* 230 Park Avenue, */}
              <br />
              Birmingham, MI 48009
              {/* New York, NY 10169 */}
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <div className="footer-navitems pb-0">
              <Nav className=" mx-auto ml-md-auto mr-md-0 mx-lg-auto" navbar>
                <NavItem>
                  <Link className="navlink" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="navlink" to="/terms-of-use">
                    Terms of Use
                  </Link>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <img
              src={googleGemini}
              alt="google-gemini"
              height={50}
              width={"auto"}
            />
            <img
              src={googleCloud}
              alt="google-cloud"
              height={30}
              width={"auto"}
            />
          </Col>
        </Row>

        <div className="wave-section-separator">
          <svg viewBox="0 0 120 28">
            <defs>
              <mask id="xxx">
                <circle cx="7" cy="12" r="40" fill="#fff" />
              </mask>

              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="2"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="
           1 0 0 0 0  
           0 1 0 0 0  
           0 0 1 0 0  
           0 0 0 13 -9"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
              <path
                id="wave"
                d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z"
              />
            </defs>

            <use id="wave3" className="wave" href="#wave" x="0" y="-2"></use>
            <use id="wave2" className="wave" href="#wave" x="0" y="0"></use>
            <g className="gooeff">
              {/* <circle className="drop drop1" cx="20" cy="2" r="1.8"  />
  <circle className="drop drop2" cx="25" cy="2.5" r="1.5"  />
  <circle className="drop drop3" cx="16" cy="2.8" r="1.2"  /> */}
              <use id="wave1" className="wave" href="#wave" x="0" y="1" />

              {/* <g mask="url(#xxx)">
    <path   id="wave1"  className="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
    </g> */}
            </g>
          </svg>
        </div>
      </footer>
    </>
  );
};

export default Footer;
