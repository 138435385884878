import React, { useState } from "react";
import {
  Button,
  Collapse,
  Container,
  Nav,
  Navbar as RC_Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  // NavLink,
} from "reactstrap";
import Logo from "../../assets/img/Landing page-slicing/mark-text-logo.png";
import LogoText from "../../assets/img/Landing page-slicing/Work Chat Text Logo.png";
import { Link } from "react-scroll";
import { NavLink as Navlink } from "react-router-dom";
import { HashRouter, useHistory } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import demo from "../../assets/img/Landing page-slicing/mark-text-logo.png";

const Navbar = (props) => {
  const history = useHistory();
  const handleDemoButtonClick = () => {
    history.push("/demo");
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <RC_Navbar className="navbar-style" expand="lg" fixed-top>
      {/* <Container> */}
      <NavbarBrand
        className="navbar-header"
        href="/"
      >
        <img src={Logo} width="auto" height="20px" style={{transform: 'translateX(-8px)'}} />
        {/* <img src={LogoText} width="" className="ml-n3 logo-text" /> */}
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className={isOpen ? "active" : ""} />
      <Collapse className="w-full" isOpen={isOpen} navbar>
        <Nav className="flex-fill justify-content-center font-family-3" navbar>
          <NavItem className="mx-0">
            <NavHashLink
              className="navlink"
              spy={true}
              smooth={true}
              duration={500}
              to="/#top"
              activeClassName="navlink-active"
            >
              Home
            </NavHashLink>
          </NavItem>
          <NavItem className="mx-0">
            <NavHashLink
              className="navlink"
              spy={true}
              smooth={true}
              duration={500}
              to="/#features"
              activeClassName="navlink-active"
            >
              Features
            </NavHashLink>
          </NavItem>
          <NavItem className="mx-0">
            <NavHashLink
              className="navlink"
              spy={true}
              smooth={true}
              duration={500}
              scroll={(el) => scrollWithOffset(el)}
              to="/#benefits"
              activeClassName="navlink-active"
            >
              Benefits
            </NavHashLink>
          </NavItem>
          <NavItem className="mx-0">
            <NavHashLink
              className="navlink"
              spy={true}
              smooth={true}
              duration={500}
              to="/#pricing"
              scroll={(el) => scrollWithOffset(el)}
              activeClassName="navlink-active"
            >
              Pricing
            </NavHashLink>
          </NavItem>
          {/* <NavItem className="mx-0">
            <Navlink
              className="navlink"
              activeClassName="navlink-active"
              to="/blogsList"
            >
              Blog
            </Navlink>
          </NavItem> */}
          <NavItem className="mx-0">
            <Navlink
              className="navlink"
              activeClassName="navlink-active"
              to="/demo"
            >
              Contact
            </Navlink>
          </NavItem>
        </Nav>
        <div className="d-flex align-items-center">
          <Button href="/auth/login" color="site-primary" className="nav-btn">
            Login
          </Button>
          <Button
            href="/auth/register"
            color="site-primary"
            className="nav-btn ml-1"
          >
            Register
          </Button>
          {/* <div className="cursor-pointer ml-5" onClick={handleDemoButtonClick}>
            <img src={demo} height={48} width={"auto"} />
          </div> */}
        </div>
      </Collapse>
    </RC_Navbar>
  );
};

export default Navbar;
